body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Inconsolata';
  background-color: #111111;
  color: #d2d0d0;
  line-height: 1.5;
}

h3 {
  font-family: 'Inconsolata';
}

.App-how-it-works h3, 
.App-faq h3, 
.App-about-contact h3, 
.App-header h1 {
  font-family: "Unica One";
}

.App {
  width: 800px;
  height: 100%;
  box-sizing: border-box; 
  text-align: center;
  padding: 20px;
  margin: 0 auto;

}

.App-header {
  margin-bottom: 40px;
  
}

.App-header h1 {
  font-size: 3em;
  margin-bottom: 10px;
  font-weight: 400;
}

.App-header h2 {
  font-size: 1em;
  margin-bottom: 20px;
  font-weight: 400;
}

.App-header p {
  font-size: 1em;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.5;
}

.heartbeat {
  display: inline-block;
  animation: heartbeat 1.5s infinite;
}

@keyframes heartbeat {
  0%, 100% {
    transform: scale(1);
  }
  15%, 45% {
    transform: scale(1.1);
  }
  30%, 60% {
    transform: scale(1);
  }
}


.arts {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; 
}

.App-how-it-works, 
.App-faq, 
.App-about-contact {
  margin-bottom: 40px;
}

.App-how-it-works h3, 
.App-faq h3, 
.App-about-contact h3 {
  font-size: 2em;
  margin-bottom: 20px;
  font-weight: 400;
}

.App-faq .faq-item {
  margin-bottom: 20px;
  color:#999;

}

.App-faq .faq-item h4 {
  font-size: 1.5em;
  margin-bottom: 10px;
  font-weight: 400;
}

.App-about-contact p {
  max-width: 600px;
  margin: 0 auto 20px;
}

.App-timeline-slider {
  margin: 0 auto;
  font-size: 0.7rem;
}

.timeline-slider {
  width: 100%;
}

.ruler {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.ruler-mark {
  position: relative;
  width: 2px;
  height: 10px;
  background-color: black;
}

.ruler-mark::after {
  content: attr(data-value);
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.9rem;
}

.prj {
  color: white;
  text-decoration: underline dotted;
  text-underline-offset: 3px; 
}

/* Consolidated media queries */
@media (max-width: 768px) {
  .App {
    width: 98%;
  }

  .App-image {
    width: 95%;
    padding: 0 15px;
    box-sizing: border-box;
  }

  .App-header h1 {
    font-size: 2.5em;
  }

  .App-timeline-slider {
    width: 90%;
    margin: 0 auto;
    font-size: 0.7rem;
  }

  .art, 
  .timeline-slider {
    width: 100%;
  }

  .ruler-mark {
    width: calc(100% / 6);
    text-align: center;
  }
}

@media (min-width: 600px) {
  .ruler-mark {
    width: calc(100% / 31);
    text-align: center;
  }
}

/* Basic styling for the table */
.table-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center; 
  overflow-x: auto;
}

table {
  width: 80%; 
  border-collapse: collapse;
  margin: 20px 0; 
}

th, td {
  border-bottom: 1px solid #666; 
  text-align: left;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

td:first-child, th:first-child {
  width: 50%; 
}

tr:hover {
  background-color: #1d1c1c; 
}

/* Divider */

.divider {
  width: 100%;
  height: 1px;
  background-color: #666;
  margin: 20px 0;
}

/* Help text */
.help-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: #ccc;
  color: rgb(0, 0, 0);
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
  position: relative; 
}

.popup {
  display: none;
  position: absolute;
  width: 200px;
  left: 50%;  
  transform: translateX(-50%);
  bottom: 100%;
  margin-bottom: 10px; 
  background-color: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  padding: 10px;
  border-radius: 3px;
  text-align: center;
  z-index: 1000;
}

.help-icon:hover .popup {
  display: block; 
}
