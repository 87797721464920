/* Add this CSS to your stylesheet */


body {
  padding: 0px;
  margin: 0px;
  background-color: #111111;
  color: #ddd;
}

.artwork {
  padding-left: 15px;
  padding-bottom: 0px;
  padding-top: 15px;
  
}

.viewer {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  text-align: left;
  width: 100%;
  height: 100%;
  vertical-align: left;
  font-family: ROboto, Helvetica, sans-serif;
  color: #999;
  font-size: small;
}


.stats {
  padding-left:10px;
  font-size: 0.7rem;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

